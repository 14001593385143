export default {
  oea: "النقابة",
  exploreOEA: "اكتشف النقابة",
  news: "الاخبار",
  logout: "خروج",
  login: "دخول",
  homeTitle: "نقابة المهندسين في طرابلس لبنان ",
  footerCopyrights: "نقابة المهندسين في طرابلس  ",
  aboutOEA: "حول النقابة",
  boardOfMembers: "أعضاء المجلس",
  organizationChart: "الهيكل التنظيمي",
  branches: "الفروع",
  title: "العنوان",
  branchesSubtitle:
    "وفقاً للمادة 6-4 من النظام الداخلي للنقابة، يكون لكل فرع هيئة مكتب مؤلف من رئيس وهو ممثل الفرع في مجلس النقابة من اربعة اعضاء تنتخبهم الهيئة العامة للفرع وفقاً للمادة 6-3 من هذا النظام. تجري انتخابات مكاتب الفروع التي انتهت مدتها من قبل الهيئة العامة لكل فرع خلال شهر ايار من كل سنة ولمدة ثلاث سنوات.",
  associations: "اللجان والروابط",
  trainings: "الدورات التدريبية",
  events: "الأحداث",
  gallery: "المعرض",
  lawsAndRegulations: "الانظمة والقوانين",
  ok: "تم",
  engZone: "زاوية المهندس",
  eorder: "النقابة الالكترونية",
  contactUs: "تواصل معنا",
  contactOea: "النقابة",
  contactLaboratory: "المختبر",
  contactTrainingCenter: "مركز التدريب",
  trainingCenter: "مركز التدريب",
  "training-Center": "مركز التدريب",
  visitUs: "مركزنا",
  visitOea: "مركز النقابة",
  visitLaboratory: "مركز المختبر",
  visitTrainingCenter: "مركز التدريب",
  latestFeeds: "جديدنا",
  aboutUs: "حول النقابة",
  upcomingEvents: "النشاطات القادمة",
  privacyPolicy: "سياسة الخصوصية",
  termOfUse: "أسئلة شائعة",
  services: "خدمات",
  shares: "مشاركة",
  likes: "إعجاب",
  views: "رؤية",
  All: "الكل",
  all: "الكل",
  "Training-Center": "مركز التدريب",
  "OEA-Support": "مشاركة النقابة",
  readMore: "اقرأ المزيد",
  dateStart: "تاريخ البدء",
  startingFrom: "إبتداءاً من:",
  dateEnd: "تاريخ الانتهاء",
  instructors: "المدربون",
  alreadyRegistered: "أنت مسجل مسبقا",
  registerClosed: "التسجيل مغلق",
  viewRegistrationDetails: "تفاصيل التسجيل",
  register: "التسجيل",
  allDay: "كل اليوم",
  start: "البدء",
  end: "الانتهاء",
  Announcements: "التعاميم",
  latestAnnouncements: "آخر التعاميم",
  announcements: "التعاميم- قرارات المجلس",
  Activities: "أنشطة",
  Mortality: "وفيات",
  discountsAndBenefits: "خصومات ومزايا",
  addCompany: "اضافة الشركة",
  companyName: "اسم الشركة",
  companyLocation: "موقع الشركة",
  uploadCompanyLocation: "موقع الشركة",
  uploadCompanyLogo: "شعار الشركة",
  "Companies Directory": "دليل الشركات",
  result: "النتيجة",
  companyPhone: "هاتف الشركة",
  companyEmail: "بريد الشركة",
  companyWebsite: "موقع الشركة",
  companyDescription: "وصف الشركة",
  "Add Company": "اضافة شركة",
  insurance: "التأمين",
  registrationTo: "التسجيل في ",
  technicalDocuments: "وثائق تقنية",
  MyRegistrationFor: "تسجيلاتي في",
  myRegistration: "تسجيلي",
  name: "الاسم",
  date: "التاريخ",
  email: "البريد الالكتروني",
  phoneNumber: "رقم الهاتف",
  whatsAppNumber: "رقم الواتساب",
  additionalInfo: "معلومات اضافية",
  viewTrainingDetails: "تفاصيل الدورة",
  cancelRegistration: "إلغاء التسجيل",
  editRegistration: "تعديل التسجيل",
  engDirectory: "دليل المهندسين",
  skills: "المهارات",
  major: "المهنة",
  myComplaints: "اقتراحاتي",
  newComplaint: "اقتراح جديد",
  complaint: "إقتراح",
  complaintImage: "الصورة",
  complaintVoice: "الصوت",
  complaintDescription: "التفاصيل",
  complaintStatus: "الحالة",
  "MY SUGGESTIONS": "اقتراحاتي",
  "ADD SUGGESTION": "اضافة اقتراح",
  recordYourComplaint: "سجل اقتراحك",
  uploadSuggestionImage: "حمّل صورة الاقتراح",
  uploadingVoice: "جاري تحميل الصوت",
  complaintAdded: "تم قبول الاقتراح",
  editComplaint: "تعديل الاقتراح",
  edit: "تعديل",
  voiceFailedToLoad: "فشل التحميل ، أعد المحاولة",
  complaintDeleted: "تم إلغاء الاقتراح",
  noComplaints: "ليس لديك اقتراحات وشكاوى",
  initiated: "تم الاستلام",
  share: "مشاركة",
  underInvestigation: "قيد التحقيق",
  closed: "مغلق",
  sureToDeleteComplaint: "هل أنت متأكد من أنك تريد حذف هذا الاقتراح؟",
  errorUploadingVoice: "خطأ في تحميل الصوت",
  ciscoTrainings: "دورات Cisco",
  calculator: "حاسبة المهندس",
  "Registration Fees": "رسوم الانتساب ",
  "Engineer Fees": "بدل اتعاب المهندس",
  back: "عودة",
  calculatorResultNote:
    "هذه النتائج تقديرية وقد تختلف حسب حالة المهندس وبلد إقامته ولا بد من مراجعة النقابة للحصول على رسوم الانتساب الفعلية.",
  usdRate: "دولار 1 = 1500 ليرة لبنانية",
  engFees: "بدل اتعاب المهندس",
  penaltyAmount: "قيمة الغرامة",
  numberOfLateYears: "عدد السنوات منذ التخرج",
  year: "سنة",
  registrationFees: "رسم الانتساب",
  annualFees: "الرسم السنوي",
  nbyears: "عدد السنوات",
  pickCalculationMethod: "طريقة الحساب",
  byArea: "حسب المساحة",
  byCost: "حسب الكلفة",
  projectTotal: "مساحة المشروع",
  sqm: "متر مربع",
  inputValue: "القيمة",
  projectCategory: "نوع المبنى",
  projectSubCategory: "وجهة استعمال المبنى",
  priceValueRange: "قيمة المشروع التقديرية",
  complexLevel: "درجة تعقيد المبنى",
  calculate: "احسب",
  constructionTable: "جدول البناء",
  pricePer1sqm: "كلفة المتر المربع الواحد حسب الجدول",
  percentageOfEngineerFees: "النسبة المؤية لأتعاب المهندس من كلفة المشروع",
  percentageOfEngFees:
    "النسبة المؤية لأتعاب المهندس من كلفة المشروع حسب الجدول",
  priceOfTerritory: "كلفة المشروع",
  primaryStudyFees: "قيمة الدراسة التمهيدية الأولية APS",
  detailedStudyFees: "قيمة الدراسة التمهيدية التفصيلية APD",
  licenceFileFees:
    "قيمة ملف الرخصة (تدفع في النقابة وترتجع كلها للمهندس بعد شهر)",
  orderFees: "رسوم النقابة النسبية",
  orderFeesOutside: "رسوم النقابة النسبية - خاص خارج الشمال",
  designFees: "قيمة التصاميم والتفاصيل التنفيذية الاجمالية",
  executiveDocumentFees: "قيمة مستندات التنفيذ",
  supervisingFees: "قيمة الاشراف على التنفيذ",
  constructionLicenseFees: "قيمة رخصة الأشغال",
  executivePlansFees: "قيمة الخرائط التنفيذية",
  returnsToPartEng: "يعود للمهندس المشارك 40% من مرتجعات النقابة",
  electEngFees: "يدفع لمهندس الكهرباء من مرتجعات النقابة",
  mechEngFees: "يدفع لمهندس الميكانيك من مرتجعات النقابة",
  quotaPercentagePartFees:
    "النسبة من بدل الأتعاب الإجمالي للمشارك (حسب نسبة توزيع الكوتا)",
  elecPerc: "النسبة من بدل الأتعاب الإجمالي لمهندس الكهرباء",
  mechanicalPerc: "النسبة من بدل الأتعاب الإجمالي لمهندس الميكانيك",
  remainingForMainEng: "صافي المتبقي للمهندس المسؤول من مرتجعات النقابة",
  fundReceipts: "مجموع قيمة الإيصالات المتوجبة للتسديد على الصندوق",
  pensionFund: "صندوق التقاعد",
  reports: "التقارير",
  payOnline: "الدفع الاكتروني",
  polls: "استطلاعات الرأي",
  jobs: "الوظائف",
  position: "الوظيفة",
  deadline: "الموعد الأخير",
  qualifications: "الشروط المطلوبة",
  summary: "ملخص",
  jobDetails: "تفاصيل",
  contactInfo: "معلومات الاتصال",
  profile: "حسابي الشخصي",
  facebook: "فايسبوك",
  instagram: "إينستاجرام",
  linkedIn: "لنكدان",
  twitter: "تويتر",
  gender: "الجنس",
  male: "ذكر",
  skills: "المهارات",
  major: "المهنة",
  searchSkill: "البحث في المهارات",
  complaints: "الاقتراحات",
  engProfile: "الملف الشخصي",
  cvUrl: "السيرة الذاتية",
  female: "أنثى",
  uploadingPdf: "جاري تحميل الملف",
  errorUploadingPdf: "خطأ في تحميل الملف",
  isPublicProfile: "مرئي في دليل المهندسين",
  major: "المهنة",
  myRegistrations: "تسجيلاتي",
  myComplaints: "اقتراحاتي",
  save: "حفظ",
  delete: "حذف",
  submit: "حفظ",
  next: "التالي",
  engineerId: "رقم تعريف المهندس",
  firstName: "الاسم",
  lastName: "الشهرة",
  middleName: "اسم الأب",
  countryCode: "رمز البلد",
  facebookAccount: "حساب Facebook",
  twitterAccount: "حساب Twitter",
  emailAddress: "عنوان البريد الالكتروني",
  linkedinAccount: "حساب Linkedin",
  instagramAccount: "حساب Instagram",
  resetPassword: "إعادة تعيين كلمة المرور",
  username: "اسم المستخدم",
  password: "كلمة المرور",
  rememberMe: "تذكرني",
  joinOEA: "حساب جديد",
  forgotPassword: "نسيت كلمة المرور",
  enroll: "التسجيل",
  changePhoneNumber: "عدّل رقم هاتفك",
  unableToConnectToServer: "حدث خطأ ، يرجى المحاولة مرة أخرى في وقت لاحق",
  firstName: "الاسم",
  lastName: "الشهرة",
  email: "البريد الإلكتروني",
  phoneNumber: "رقم الهاتف",
  allFieldMandatory: "كل الحقول إلزامية",
  enrollThankYou: "شكراً لاهتمامك بالتسجيل مع OEA طرابلس",
  enrollDetailsSubmitted: "تم إرسال التفاصيل الخاصة بك بنجاح",
  enrollInfo:
    " نحن الآن نراجع معلوماتك للتحقق من الأهلية. ستحصل على رسالة قصيرة على رقم هاتفك فيها رمز خلال الـ 24 ساعة القادمة.",
  enrollCode: "أكتب الرمز الذي تلقيته",
  didntReceiveEnrollCode: "لم تستلم الكود؟",
  resendCode: "إعادة إرسال الرمز",
  correctPhone: "تصحيح رقم الهاتف",
  welcomeInfo: "مرحبا بكم في التطبيق المحمول OEA طرابلس",
  createPasswordInfo: "قم بإنشاء كلمة المرور الخاصة بك",
  confirmPassword: "تأكيد كلمة المرور",
  goToSignIn: "انتقل إلى تسجيل الدخول",
  iHaveACode: "لدي رمز لتفعيل الحساب",
  activateAccount: "تفعيل حساب",
  enterEngId: "ادخل رقم التعريف النقابي",
  resetCodeReceived: "هل تلقيت رمز إعادة تعيين كلمة المرور؟",
  rememberedPassword: "العودة إلى",
  sms: "رسالة على الهاتف",
  pleaseChooseResetPasswordMethod:
    "هل ترغب في الحصول على الرمز لإعادة تعيين كلمة المرور الخاصة بك عبر:",
  requestToJoin: "إرسال طلب إنضمام",
  tryAgainError: "الرجاء اعادة المحاولة لاحقا",
  fillFormFields: " يجب ملء جميع البيانات للمتابعة",
  useCodeToLogin: "الرجاء استخدام من رمز التحقق لتسجيل الدخول",
  enterNewPass: "أدخل كلمة مرور جديدة",
  confirmNewPass: "تأكيد كلمة المرور الجديدة",
  alreadySentRequest: "لقد أرسلت طلب تسجيل الدخول؟",
  verifyEnrollement: "تحقق من التسجيل",
  alreadyHaveAccount: "هل لديك حساب؟",
  confirmEnroll: "تأكيد التسجيل",
  signinSentence: "قم بتسجيل الدخول للاستفادة من خدمات المهندسين عبر الإنترنت",
  smsCode: "رمز التحقق عبر الرسائل القصيرة",
  retypePass: "أعد إدخال كلمة السر",
  facingProblem: "هل تواجه مشكلة؟",
  sendNewRequest: "أرسل طلب تسجيل جديد",
  send: "أرسل",
  newUser: "مستخدم جديد؟",
  joinToOea: "انضم إلى OEA",
  forgotPass: "هل نسيت كلمة السر؟",
  fullName: "الاسم الكامل",
  addPerson: "إضافة شخص",
  removePerson: "إزالة الشخص",
  comingSoon: "قريبا",
  pleaseWait: "الرجاء الانتظار...",
  "register-to-event": "التسجيل في النشاط",
  "register-to-training": "التسجيل في التدريب",
  home: "الصفحة الرئيسية",
  enterPassword: "الرجاء ادخال كلمة سر",
  eorder: "النقابة الالكترونية",
  introSentence:
    "للقيام بمعاملاتكم الادارية والمالية الرجاء الضغط على الرابط التالي : ",
  clickHere: "اضغط هنا",
  renewJobDeclaration: "تجديد البيان المهني للسنة الحالية",
  continueFeesPayment: "استكمال دفع الرسوم للمعاملات السابقة",
  statementYear: "البيان المهني للسنة",
  generalInfo: "معلومات عامة",
  engineerName: "اسم المهندس",
  chapter: "الفصل",
  subChapter: "الفصل الفرعي",
  workType: "نوع العمل",
  workLocation: "مكان العمل",
  homeAddress: "عنوان المنزل",
  country: "دولة",
  province: "محافظة",
  district: "قضاء",
  city: "المدينة",
  street: "شارع",
  building: "ﺍﻟﺒﻨﺎﻳﺔ",
  floor: "الطابق",
  appartment: "الشقة",
  mobile: "الخليوي",
  isForMailing: "(للبريد)",
  workAddress: "عنوان العمل",
  oops: "حدثت مشكلة أثناء تحميل الصفحة",
  law: `عملا بالمادة ٨ - ٩ من النظام الداخلي :
        على المهندس ان يدون شخصيا كافة المعلومات الواردة في البيان المهني العائد له على مسؤوليته.
         وان أية معلومات خاطئة واردة في البيان المذكور تعرض صاحبها الى توقيف معاملات في النقابة 
         وفقا لقرار يصدر من مجلس النقابة بهذا للخصوص. كما تعرضه الى العقوبات التأديبية المنصوص عليها قانونيا. `,
  quotaLaw:
    "إن المهندسين الاستشاريين ( الذين يستحصلون على كوتا) هم فقط المخولين لتجديد البيان المهني في بداية السنة أي بتاريخ firstDate . أما المهندسين الاخرين فيتم تجديد بياناتهم المهنية في بداية السنة النقابية أي بتاريخ secondDate",
  visitOEA: `ان أي تغيير بوضعية المهندسين الاستشاريين يتوجب عليكم الحضور شخصيا الى النقابة أو التواصل معنا <br/><br/>على الرقم التالي: 06413110 <br/>أو عبر البريد الالكتروني: info@oea-Tripoli.org.lb`,
  statusPreviousYear: "وضعية بيانكم المهني للسنة السابقة هي : ",
  changePosition: "تعديل بالوضعية",
  continueSamePosition: "استمرارية في الوضعية ذاتها",
  delayVisitOea: `ان تاريخ آخر بيان مهني لحضرتكم يعود لأكثر من سنة ولذلك يتوجب عليكم الحضور شخصيا الى النقابة أو التواصل معنا<br/><br/> على الرقم التالي: 06413110 <br/>أو عبر البريد الالكتروني: info@oea-Tripoli.org.lb`,
  everythingUpToDate: ` ان بيانكم المهني لهذه السنة قد تم تقديمه سابقا. شكرا لكم. يمكنكم فقط الاطلاع على بيانكم المهني السابق.`,
  updatesText: "إذا كان هناك أي تغيير ، يرجى تعديل عنوان منزلك وعنوان عملك",
  tryAgain: "اضغط لاعادة المحاولة",
  IAccept: "أنا أوافق",
  clickToContinue: "اضغط هنا للاكمال",
  uploadDocuments: "الرجاء تحميل هذه المستندات",
  noDocuments: "لا توجد مستندات مطلوبة",
  remarks: "ملاحظات",
  responsibility:
    "ان المعلومات الواردة هي على مسؤوليتكم وأية معلومات خاطئة تعرضكم الى توقيف معاملاتكم والى عقوبات تأديبية",
  finish: "إنهاء المعاملة",
  workForEngineer: "يعمل لدى",
  workForEngineerID: "رقم تعريف الشريك",
  partnerEngineer: "الشركة/المهندس",
  partnerEngineerId: "رقم التعريف للشركة/المهندس",
  quotaForEngineer: "حق الكوتا ل",
  quotaForEngineerID: "رقم تعريف المهندس المستفيد من الكوتا",
  govInstitute: "الجهة الحكومية",
  govInstituteID: "رقم تعريف الجهة الحكومية",
  govInstituteDesc: "وصف الجهة الحكومية",
  downloadFile: "لتنزيل عقد الشراكة الرجاء الضغط هنا",
  downloadFileReceipt: "لتنزيل الايصال الرجاء الضغط هنا",
  pictureNote:
    "يرجى ملاحظة أنه يمكنك فقط تحميل صورة أو ملف بحجم أقصاه ١ ميغابايت",
  bigFile: "الرجاء تحميل ملف اقل من ١ ميغابايت",
  requestEnrollment:
    "طلبك قيد المراجعة <br/><br/>سوف نتحقق من معلوماتك ونرسل لك رمز على رقمك وبريدك الإلكتروني لتتمكن من تسجيل الدخول ",
  userDoesNotExists: "رقم المستخدم غير موجود",
  wrongPassword: "كلمة مرور خاطئة",
  wrongData: "بيانات خاطئة",
  alreadyExists: "هذا المستخدم موجود مسبقاً",
  doseNotExists: "هذا المستخدم غير موجود",
  emailDoesNotExists: "البريد الإلكتروني غير صحيح",
  pendingReq: "طلبك في انتظار الموافقة",
  expiredCode: "انتهت صلاحية رمز التحقق",
  requestDoesNotExist: "الطلب غير موجود",
  codeUsed: "رمز التحقق مستخدم بالفعل",
  wrongCode: "رمز التحقق خاطئ",
  confirmEnrollment: "تأكيد التسجيل",
  yourCV: "سيرتك الذاتية",
  updateTypeLocationWork: "تعديل نوع وموقع العمل",
  requestExists: "لقد أرسلت طلبًا مسبقا",
  engZoneSentence:
    "تقدم زاوية المهندسين باقة مخصصة من الخدمات والميزات لأعضاء النقابة لتعزيز خبرتهم ودعم احتياجاتهم",
  loginSentence: `سجلوا الدخول و استفيدوا من زاوية المهندسين`,
  "Building License Transactions": "معاملات ترخيص البناء",
  "Restoration / Connection Permit / Demolition / Designation":
    "معاملات ترميم / وصل بالتصريح / هدم / تصوينة",
  searchIn: "البحث في",
  registrationclosed: "التسجيل مغلق",
  noDataAvailable: "لا توجد بيانات متاحة",
  noTrainingsAvailable: "لا توجد دورات متاحة",
  wrongConfirmPassword: "يرجى التأكد من تطابق كلمات السر الخاصة بك",
  wrongOldPassword: "يبدو أن كلمة مرورك القديمة غير صحيحة",
  changePassword: "تغيير كلمة المرور",
  passwordChangedSuccessfully:
    "تم تغيير كلمة المرور الخاصة بك بنجاح. استخدم كلمة المرور الجديدة لتسجيل الدخول",
  personInfo: "معلومات شخصية",
  status: "الحالة",
  financialStatement: "المعاملة المالية",
  mandatoryUnpaidDues: "المستحقات غير المسددة الإلزامية",
  optionalUnpaidDues: "المستحقات غير المسددة",
  unpaidReceipt: "إيصال غير مدفوع",
  date: "التاريخ",
  dueDate: "تاريخ الاستحقاق",
  amount: "المبلغ",
  numberReceipt: "ايصال رقم",
  description: "الوصف",
  payReceiptB: "يرجى تسديد ايصالاتك الغير مدفوعة قبل اصدار ايصال جديد",
  createdJobDeclaration: "لقد تم انجاز معاملاتكم الادارية",
  generatedReceipt: "لقد تم انجاز معاملتكم المالية بنجاح",
  printReceipt: "العودة لطباعة الايصال",
  noFinancialStatment: "لا يوجد لديكم اي معاملات مالية",
  print: "طباعة",
  cantPrint:
    "هذه المعاملة قديمة، في حال كانت مدفوعة يرجى ارسال نسخة عبرالبريد الالكتروني التالي",
  skip: "تخطي",
  batch: "دفعة",
  paymentTerms: "شروط الدفع",
  years: "السنوات",
  withoutInterest: "من دون فوائد",
  and: "و",
  feeAmount: "مبلغ الرسوم",
  interestAmount: "مبلغ الفائدة",
  pendingOperation: "لديك معاملة اشتراك سنوي سابق معلق، يجب مراجعة النقابة",
  annualSubscription: "اشتراك سنوي",
  fileCode: "رقم الملف",
  engineerNumber: "رقم المهندس",
  fileType: "نوع الملف",
  description: "الوصف",
  notes: "الملاحظات",
  dateOfReceipt: "تاريخ الاستلام",
  deliveryDate: "تاريخ التسليم",
  currentStatusFile: "الوضع الحالي للملف",
  lastUserHasFile: "اسم اّخر مستخدم أو الموظف بحوذته الملف",
  licenseFileSentence:
    "للقيام بمعاملاتكم الفنية الرجاء الضغط على الرابط التالي : ",
  licenseFile: "المعاملات الفنية",
  enterFileCode: "ادخل رمز معاملتك الفنية",
  extension: "الرقم الداخلي",
  employeeName: "اسم الموظف",

  //SEO Description
  aboutOeaDescription: "نبذة عن نقابة المهندسين والمعماريين في طرابلس",
  boardmembersDescription: "مجلس أعضاء نقابة المهندسين والمعماريين في طرابلس",
  branchesDescription: "الفروع بترتيب المهندسين والمعماريين في طرابلس",
  committeesDescription: "لجان نقابة المهندسين والمعماريين في طرابلس",
  organizationDescription:
    "الهيكل التنظيمي لنقابة المهندسين والمعماريين في طرابلس",
  benefitsDescription:
    "الخصومات والمزايا التي تقدمها نقابة المهندسين والمعماريين في طرابلس",
  companiesDescription: "دليل الشركات حيث تجد العديد من الشركات بتفاصيلها",
  contactusDescription: "تواصل مع نقابة المهندسين والمعماريين في طرابلس",
  insuranceDescription:
    "خدمة التأمين التي تقدمها نقابة المهندسين والمعماريين في طرابلس",
  engineersDirectoryDescription:
    "دليل المهندسين حيث يمكنك العثور على عدد كبير من ملفات تعريف المهندسين مع معلومات الاتصال الخاصة بهم",
  jobDeclarationDescription:
    "تجديد تصريح عمل المهندسين عبر الإنترنت وبطريقة سريعة",
  eventsDescription:
    "قائمة بجميع الأحداث التي قام بها نقابة المهندسين والمعماريين في طرابلس",
  galleryDescription:
    "معرض لصور الفعاليات والأنشطة بالتعاون مع نقابة المهندسين والمعماريين في طرابلس",
  lawsDescription: "قوانين وأنظمة نقابة المهندسين والمعماريين في طرابلس",
  complaintsDescription: "اقتراحات من قبل المهندسين",
  myregistrationsDescription:
    "تسجيلاتي للفعاليات والدورات التدريبية بترتيب المهندسين والمعماريين في طرابلس",
  newsDescription:
    "قائمة بجميع الأخبار والإعلانات بترتيب المهندسين والمعماريين في طرابلس",
  technicalDocumentsDescription:
    "المستندات الفنية التي قد تكون مفيدة للمهندسين",
  trainingsDescription:
    "قائمة بجميع التدريبات التي تقدمها نقابة المهندسين والمعماريين في طرابلس",
  visitusDescription: "زيارة نقابة المهندسين والمعماريين في طرابلس",
  engineerZoneDescription:
    "منطقة المهندسين تقدم خدمات وباقات متنوعة لجميع المهندسين والمعماريين",
  homepageDescription:
    "الصفحة الرئيسية للموقع الرسمي لنقابة المهندسين والمعماريين في طرابلس",
  jobsDescription:
    "الوظائف المنشورة على الموقع الرسمي لنقابة المهندسين والمعماريين في طرابلس",
  reportsDescription:
    "تقارير وإحصاءات تتعلق بترتيب المهندسين والمعماريين في طرابلس",
  calculatorDescription:
    "الآلة الحاسبة المتضمنة لرسوم التسجيل وأتعاب المهندس تساعد المهندسين في احتساب الرسوم بسهولة وسرعة",
  technicalOfficePubs: "مطبوعات المكتب الفني",
  technicalOffice: "المكتب الفني",
  download: "تحميل",
  arbitration: "مركز التحكيم",
  arbitrationCenter: "مركز الوساطة والتحكيم الهندسي",
  deleteAccount: "حذف الحساب",
  deleteAccountMsg: "لحذف حسابك، يرجى الاتصال بنا ",
  statuteOfArbitration: "النظام الأساسي لمركز التحكيم الهندسي",
  arbitrationRules: "قواعد التحكيم المعتمدة لدى مركز التحكيم الهندسي",
  arbitrationCosts: "تكاليف التحكيم",
  arbitrationClause: "البند التحكيمي",
  arbitrationRequest: "طلب تحكيم",
  respondingToArbitrationRequest: "الرد على طلب التحكيم",
  arbitrationAcceptance: "بيان قبول المحكم لمهمته واعلان الاستقلالية والحيادية",
  formsAndDocs: "نماذج ومستندات",
  presidentWord: "كلمة النقيب",
  syndicateOverview: "لمحة عن النقابة",
  joinOea: "انتسب للنقابة، شروط الانتساب",
  financialAndMedicalTransactions: "المعاملات المالية والاستشفائية",
  financialAndMedicalTransactionsDesc: `تؤمن نقابة المهندسين تغطية تأمين الإستشفاء والجراحة للمهندسين المنتسبين الى النقابة وعائلاتهم ووالديهم واخوتهم وعائلاتهم.
يمتد عقد التأمين على سنة نقابية، تبدأ من الأول من آذار من كل عام وينتهي في آخر شباط من العام التالي، اخر ملهة للاشتراك بالتامين هي نهاية شهر نيسان.`,
  monthlyReports: "التقارير الشهرية",
  webinarsSchedule: "جدول المؤتمرات والندوات الشهرية في مبنى النقابة",
  sideMenuTitle: "القائمة",
  changeMode: "تغيير الوضع",
  signIn: "تسجيل الدخول",
  signUp: "إنشاء حساب",
  moreAnnouncements: "لمزيد من التعاميم",
  moreNews: "لمزيد من الأخبار",
  noInfo: "لا يوجد معلومات",
  nakabaBuilding: "مبنى النقابة",
  internalNumbers: "رمز المكتب الداخلي",
  searchPlaceholder: "ابحث عن بلدك",
  noCountry: "لا يوجد بلد بهذا الاسم",
  akkarBuilding: "مكتب عكار",
  lab: "المختبر",
  currentTrainings: "الدورات الحالية",
  futureTrainings: "الدورات القادمة",
  pastTrainings: "الدورات السابقة",
  membershipTerms: "شروط الانتساب",
  technicalOfficeDesc: `أهلاً بكم في صفحة قسم تسجيل المعاملات.
يقوم المكتب الفني بتسجيل معاملات البناء والمساحة والتربة العائدة للمهندسين المنتسبين لنقابة المهندسين في طرابلس.`,
  previousCouncils: "المجالس السابقة",
  referenceNumber: "رقم المرجع",
  amountInUSD: "المبلغ بالدولار الأمريكي ($)",
  pay: "دفع",
  enterEngineerId: "أدخل رقم المهندس",
  enterReferenceNumber: "أدخل رقم المرجع",
  onlinePaymentFormTitle: "دفع مستحقات النقابة",
  loadingPayment: "يتم الدفع",
  paymentSuccess: "تمت عملية الدفع بنجاح",
  paymentFailed: "فشلت عملية الدفع، حاول مرة أخرى",
  gettingPaymentStatus: "جاري التحقق من حالة الدفع...",
};
