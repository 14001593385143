"use client";
import { faSignOutAlt } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Icon } from "@iconify/react";
import { MenuItem, Select } from "@material-ui/core";
import {
  AccountCircle,
  ArrowBack,
  ArrowForward,
  Call,
  ChevronLeft,
  ChevronRight,
  ClearAll,
  Language,
  Person,
  Payment,
} from "@material-ui/icons";
import Link from "components/Link";
import { pagePadding } from "data/commonClasses";
import { newsLinks, oeaLinks, technicalOfficeLinks } from "data/menuItems";
import { fetchFromAPI, setCookie } from "functions";
import { useRouter } from "next/router";
import { useContext, useEffect, useState } from "react";
import { useMediaQuery } from "react-responsive";
import useStyles from "styles/Header.Style";
import { twMerge } from "tailwind-merge";
import useTranslation from "translations/hooks/useTranslation";
import Button from "./Button";
import SessionContext from "./session/SessionContext";
import Text from "./Text";

export default function Header() {
  const classes = useStyles();
  const router = useRouter();
  const { t } = useTranslation();
  let mobileWidth = 1024;
  let mobileDimensions = { query: "(max-width: 1024px)" };
  let isMobile = useMediaQuery(mobileDimensions);
  let {
    actions: { signout, setSession },
    session: { user, lang },
  } = useContext(SessionContext);

  let isLoggedIn = user && user?.authToken && user?._id;
  const [isOpen, setIsOpen] = useState(true);
  const [language, setLanguage] = useState(lang);
  const [navbarBg, setNavbarBg] = useState("");

  useEffect(() => {
    window.innerWidth > mobileWidth ? setIsOpen(true) : setIsOpen(false);

    window.addEventListener("resize", (e) => {
      window.innerWidth > mobileWidth ? setIsOpen(true) : setIsOpen(false);
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const getNavbarBg = async () => {
    let requests = [
      {
        key: "navbarBackground",
        apiName: "oea-pages-content",
        reqBody: { key: "navbar-background", lang },
      },
    ];
    let response = await fetchFromAPI({ api: "home", reqBody: requests });
    setNavbarBg(response?.navbarBackground?.response);
    // console.log({getNavbarBg:response});
    // return response?.navbarBackground?.response
  };

  useEffect(() => {
    getNavbarBg();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  function handleLanguage(value) {
    setCookie("lang", value, 30);
    setLanguage(value);
    setSession({ lang: value });
    let {
      asPath,
      query: { lang = "en" },
    } = router;
    let path = asPath.replace(lang, value);
    if (typeof location !== "undefined" && location)
      location.href = asPath == "/" ? `/${value}` : path;
  }

  function handleClose() {
    if (window.innerWidth > mobileWidth) return;
    setIsOpen(false);
  }

  function ProfilePicture() {
    let userCode = user?.emails?.length ? user?.emails?.[0]?.address : "";
    return (
      <Link href="/userProfile" as="/userProfile">
        <Button
          mainClass="mt-0"
          IconComp={AccountCircle}
          title={user?.profile?.firstName || userCode}
        />
      </Link>
    );
  }

  const renderMenuLink = ({ links, title, i }) => {
    let extraClass = ` dropdown${i}`;
    return (
      <div className={classes.divNavLink}>
        <div className="flex flex-row items-center cursor-pointer gap-2">
          <a className={classes.navlinkList}>
            <Text text={t(title)} className="w-full" />
          </a>
          <Icon icon="bxs:down-arrow" className="text-black" />
        </div>

        <div
          className={`${
            lang == "ar"
              ? classes.dropdownAR + extraClass
              : classes.dropdownEN + extraClass
          } z-30`}
          style={{ textAlign: lang == "ar" ? "right" : "left" }}
        >
          {links?.map((oneLink, index) => (
            <Link key={index} href={oneLink?.href} as={oneLink?.as}>
              <a className={classes.linkContent} onClick={handleClose}>
                <Text text={t(oneLink?.title)} />
              </a>
            </Link>
          ))}
        </div>
      </div>
    );
  };
  const renderLink = ({ title, href, as, onlyLoggedIn = false }) => {
    return (isLoggedIn && onlyLoggedIn) || !onlyLoggedIn ? (
      <div className={classes.divNavLink}>
        <Link href={href} as={as}>
          <a className={classes.navlink} onClick={handleClose}>
            <Text text={t(title)} className=" w-full " />
          </a>
        </Link>
      </div>
    ) : null;
  };
  return (
    <div className="mb-5 relative">
      {navbarBg?.image && (
        <img
          src={navbarBg?.image}
          width={300}
          height={300}
          className={twMerge(
            "h-[4.5rem] w-20 object-cover aspect-square absolute opacity-10 md:opacity-100 z-[1] bottom-0",
            lang == "ar" ? "left-0" : "right-0 scale-x-[-1]"
          )}
        />
      )}
      <div
        className={twMerge(
          "bg-oeaMain flex flex-row justify-between py-4 ",
          pagePadding
        )}
      >
        {!isMobile ? null : !isOpen ? (
          <div className="flex flex-row items-center text-white">
            <label htmlFor="my-drawer-4">
              <Icon
                icon={"mingcute:menu-fill"}
                className="cursor-pointer"
                width={32}
                height={32}
                color="#FFF"
              />
            </label>
          </div>
        ) : null}
        <div className="flex flex-row items-start justify-end md:w-full">
          <div className="flex flex-row items-center justify-center bg-white rounded-btn mx-4 b">
            <Select
              IconComponent={() => null}
              className={`py-1`}
              style={{ color: "#F29F05" }}
              value={language || "en"}
              label="Language"
              onChange={(e) => handleLanguage(e?.target?.value)}
              disableUnderline
            >
              <MenuItem value="en" className="hover:bg-orange">
                <Text bold text={"English"} />
              </MenuItem>
              <MenuItem value="fr">
                <Text bold text={"Français"} />
              </MenuItem>
              <MenuItem value="ar">
                <Text bold text={"العربية"} />
              </MenuItem>
            </Select>
            <Language className="text-orange mx-1" />
          </div>
          <div className="flex flex-row items-start gap-4 justify-end w-full">
          {isMobile || !isLoggedIn ? null : (
              <Button
                linkProps={{
                  href: `/${lang}/online-payment`,
                  as: `/${lang}/online-payment`,
                }}
                whiteBg
                IconComp={Payment}
                title={t("payOnline")}
                mainClass="mt-0"
              />
            )}

            {isMobile ? null : (
              <Button
                linkProps={{
                  href: `/${lang}/contactus`,
                  as: `/${lang}/contactus`,
                }}
                whiteBg
                IconComp={Call}
                title={t("contactUs")}
                mainClass="mt-0"
              />
            )}

            {user && user?.authToken && user?._id ? (
              ProfilePicture()
            ) : !isMobile ? (
              <Button
                mainClass="mt-0"
                linkProps={{
                  href: `/${lang}/signin`,
                  as: `/${lang}/signin`,
                }}
                IconComp={Person}
                title={t("signIn")}
              />
            ) : null}
            {isMobile ? (
              <div>
                <div
                  style={{ backgroundColor: "#F29F05" }}
                  className="rounded-[10px] p-2"
                >
                  <Link href={`/signin`} as={`/signin`}>
                    <a>
                      <Person className={"text-white mx-1 "} />
                    </a>
                  </Link>
                </div>
              </div>
            ) : null}
            {isMobile || !isLoggedIn ? null : (
              <Button
                onClick={signout}
                whiteBg
                iconName={"basil:logout-solid"}
                title={''}
                mainClass="mt-0"
                iconClassName={twMerge(
                  'mx-0',
                  lang !== "ar" && 'scale-x-[-1]'
                )}
                className={'px-2'}
              />
            )}
          </div>
        </div>
      </div>

      <div
        className={twMerge(
          `border-b-2 z-10 border-[#E5E8EB] flex overflow-hidden gap-5 w-full justify-between md:py-0 `,
          pagePadding
        )}
      >
        <Link href="/" as="/" className="z-10">
          <div className={"flex flex-row items-center my-4"}>
            <img
              src="/logo.png"
              alt="OEA Logo"
              width={100}
              height={100}
              className="w-14 h-14 cursor-pointer"
            />
            <p
              dangerouslySetInnerHTML={{ __html: t("homeTitle") }}
              className={"w-full mx-4 font-bold text-input text-black"}
            />
          </div>
        </Link>

        {!isOpen ? null : (
          <div className="flex z-10 self-stretch w-full justify-start gap-8 items-center ">
            {isMobile ? null : (
              <div className="flex flex-row items-center justify-start gap-8">
                {renderMenuLink({
                  i: 1,
                  title: "oea",
                  links: oeaLinks,
                })}
                {renderLink({
                  title: "financialAndMedicalTransactions",
                  href: "/financialMedicalTransactions",
                  as: "/financialMedicalTransactions",
                })}
                {renderMenuLink({
                  i: 2,
                  title: "technicalOffice",
                  links: technicalOfficeLinks,
                })}
                {renderLink({
                  title: "arbitrationCenter",
                  href: "/arbitration",
                })}
                {renderLink({
                  href: "/trainings/1",
                  as: "/trainings/1",
                  title: "trainingCenter",
                })}
                {renderMenuLink({
                  i: 3,
                  title: "news",
                  links: newsLinks,
                })}
                {/* {renderLink({
                  onlyLoggedIn: true,
                  title: "eorder",
                  href: "/eorder",
                  as: "/eorder",
                })} */}
              </div>
            )}

            {user && user.authToken && user._id ? (
              <div className={classes.logoutDivLink} onClick={signout}>
                <FontAwesomeIcon icon={faSignOutAlt} />
                <div className={classes.divNavLink} style={{ padding: 0 }}>
                  <a className={classes.navlink} style={{ padding: "0px 5px" }}>
                    <Text text={t("logout")} />
                  </a>
                </div>
              </div>
            ) : null}
          </div>
        )}
      </div>
    </div>
  );
}
